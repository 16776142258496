import { getLoggedInUser } from 'helpers/api/apiCore';
import { appRoles } from 'pages/uikit/utils';

export default function useUser() {
  const loggedInUser = getLoggedInUser();
  const isSubcontratista = loggedInUser?.role === appRoles.Subcontratista;
  const isSuperAdmin = loggedInUser?.role === appRoles.SuperAdmin;
  const isSupervisor = loggedInUser?.role === appRoles.Supervisor;
  const isSistemAdmin = loggedInUser?.role === appRoles.SistemAdmin;
  const isProyectAdmin = loggedInUser?.role === appRoles.ProjectAdmin;
  const isFiscalizador = loggedInUser?.role === appRoles.Fiscalizador;
  const isMandante = loggedInUser?.role === appRoles.Mandante;
  const userRole = loggedInUser?.role;
  return {
    loggedInUser,
    isSubcontratista,
    isSuperAdmin,
    userRole,
    isSupervisor,
    isSistemAdmin,
    isProyectAdmin,
    isFiscalizador,
    isMandante,
  };
}
