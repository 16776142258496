import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Proyecto from './proyectos/reducers';

export default combineReducers({
    Auth,
    Layout,
    Proyecto
});
