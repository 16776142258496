import { ProyectoModel } from "pages/administracion/proyectos/proyectoModel";
import { ProyectoActionTypes } from "./constants";

const INIT_STATE = {
  proyectos: [],
  loading: true,
  selectedProyectoId: '0'
};

export type ProyectoState = {
    proyectos: Array<ProyectoModel>;
    loading: boolean;
    selectedProyectoId: string;
};

type ProyectoActionType = {
  type: ProyectoActionTypes,
  payload: {
    proyectos: Array<ProyectoModel>,
    selectedProyectoId: string
  }
}

const Proyecto = (state: ProyectoState = INIT_STATE, action: ProyectoActionType) => {
  switch (action.type) {
    case ProyectoActionTypes.GET_PROYECTOS_STARTED:
      return {...state}
    case ProyectoActionTypes.GET_PROYECTOS_COMPLETED:
      return {
        ...state, 
        loading: false,
        proyectos: action.payload.proyectos,
        selectedProyectoId: action.payload.selectedProyectoId
      }
    case ProyectoActionTypes.SELECT_PROYECTO:
      return {
        ...state,
        selectedProyectoId: action.payload.selectedProyectoId
      }
    default:
      return state
  }
}

export default Proyecto;
