import { appRoles } from 'pages/uikit/utils';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';
import { useRoutes } from 'react-router-dom';
import PageNotFound from 'pages/PageNotFound';
import PrivateRoute from './PrivateRoute';
import React, { Suspense } from 'react';
import Root from './Root';

// lazy load all the views
const vlClientes = React.lazy(() => import('pages/super/vlClientes/VLClientes'));
const addEditVLCliente = React.lazy(() => import('pages/super/vlClientes/AddEditVLCliente'));

const vlContactos = React.lazy(() => import('pages/super/vlContactos/VLContactos'));
const addEditVLContacto = React.lazy(() => import('pages/super/vlContactos/AddEditVLContacto'));

const vlContactoTipos = React.lazy(() => import('pages/super/vlContactoTipos/VLContactoTipos'));
const AddEditVLContactoTipo = React.lazy(() => import('pages/super/vlContactoTipos/AddEditVLContactoTipo'));

const panelDeControl = React.lazy(() => import('home/PanelDeControl'));

const usuarios = React.lazy(() => import('pages/sistema/usuarios/Usuarios'));
const addEditUsuario = React.lazy(() => import('pages/sistema/usuarios/AddEditUsuario'));
const usuarioSettings = React.lazy(() => import('pages/sistema/usuarios/Settings'));

const festivos = React.lazy(() => import('pages/sistema/festivos/Festivos'));
const addEditFestivo = React.lazy(() => import('pages/sistema/festivos/AddEditFestivo'));

const empresas = React.lazy(() => import('pages/administracion/empresas/Empresas'));
const addEditEmpresa = React.lazy(() => import('pages/administracion/empresas/AddEditEmpresa'));
const empresaDetails = React.lazy(() => import('pages/administracion/empresas/EmpresaDetails'));
const addSubcontratista = React.lazy(() => import('pages/administracion/empresas/AddSubcontratista'));

const trabajadores = React.lazy(() => import('pages/administracion/trabajadores/Trabajadores'));
const addEditTrabajador = React.lazy(() => import('pages/administracion/trabajadores/AddEditTrabajador'));
const trabajadorDetails = React.lazy(() => import('pages/administracion/trabajadores/TrabajadorDetails'));

const remuneraciones = React.lazy(() => import('pages/administracion/remuneraciones/Remuneraciones'));
const addEditRemuneracion = React.lazy(() => import('pages/administracion/remuneraciones/AddEditRemuneracion'));

const proyectos = React.lazy(() => import('pages/administracion/proyectos/Proyectos'));
const addEditProyecto = React.lazy(() => import('pages/administracion/proyectos/AddEditProyecto'));
const proyectoDetails = React.lazy(() => import('pages/administracion/proyectos/ProyectoDetails'));

const partidas = React.lazy(() => import('pages/administracion/partidas/Partidas'));
const addEditPartida = React.lazy(() => import('pages/administracion/partidas/AddEditPartida'));

const cargos = React.lazy(() => import('pages/administracion/cargos/Cargos'));
const addEditCargos = React.lazy(() => import('pages/administracion/cargos/AddEditCargos'));

const turnos = React.lazy(() => import('pages/administracion/turnos/Turnos'));
const addEditTurno = React.lazy(() => import('pages/administracion/turnos/AddEditTurno'));

const cuadrillas = React.lazy(() => import('pages/administracion/cuadrillas/Cuadrillas'));
const addEditCuadrillas = React.lazy(() => import('pages/administracion/cuadrillas/AddEditCuadrillas'));

const estadoTrabajadores = React.lazy(() => import('pages/administracion/estadoTrabajadores/EstadoTrabajadores'));
const addEditEstadoTrabajador = React.lazy(
  () => import('pages/administracion/estadoTrabajadores/AddEditEstadoTrabajadores')
);

const tipoDocumentos = React.lazy(() => import('pages/administracion/tipoDocumentos/TipoDocumentos'));
const addEditTipoDocumento = React.lazy(() => import('pages/administracion/tipoDocumentos/AddEditTipoDocumentos'));

const bibliotecaDocumentos = React.lazy(() => import('pages/administracion/bibliotecaDocumentos/BibliotecaDocumentos'));

const documentosPorValidar = React.lazy(() => import('pages/administracion/documentosPorValidar/DocumentosPorValidar'));

const tableroDeControl = React.lazy(() => import('pages/manoObra/tableroDeControl/TableroDeControl'));
const tableroDeControlV2 = React.lazy(() => import('pages/manoObra/tableroDeControl/TableroDeControlV2'));

const asistencias = React.lazy(() => import('pages/manoObra/asistencias/Asistencias'));
const addEditAsistencia = React.lazy(() => import('pages/manoObra/asistencias/AddEditAsistencia'));

const asignaciones = React.lazy(() => import('pages/manoObra/asignaciones/Asignaciones'));
const editAsignacion = React.lazy(() => import('pages/manoObra/asignaciones/EditAsignacion'));

const trabajadoresSinCargo = React.lazy(() => import('pages/manoObra/trabajadoresSinCargo/TrabajadoresSinCargo'));
const trabajadoresSinTurno = React.lazy(() => import('pages/manoObra/trabajadoresSinTurno/TrabajadoresSinTurno'));

const tareas = React.lazy(() => import('pages/avance/tareas/Tareas'));
const addEditTarea = React.lazy(() => import('pages/avance/tareas/AddEditTarea'));

const restricciones = React.lazy(() => import('pages/avance/restricciones/Restricciones'));
const addEditRestriccion = React.lazy(() => import('pages/avance/restricciones/AddEditRestriccion'));

const etiquetas = React.lazy(() => import('pages/avance/etiquetas/Etiquetas'));
const addEditEtiqueta = React.lazy(() => import('pages/avance/etiquetas/AddEditEtiqueta'));

const miCuenta = React.lazy(() => import('pages/account/MiCuenta'));
const notificaciones = React.lazy(() => import('pages/account/Notificaciones'));

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));

const Logout2 = React.lazy(() => import('pages/account/Logout2'));
const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('pages/account/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('pages/account/LockScreen2'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));
const AccessDenied = React.lazy(() => import('pages/error/AccessDenied'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();
  const routes = [
    { path: '/', element: <Root /> },
    {
      // public routes
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: 'account',
          children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
            { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
            { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
            { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
            { path: 'logout2', element: <LoadComponent component={Logout2} /> },
            { path: '*', element: <PageNotFound /> },
          ],
        },
        {
          path: 'error-404',
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: 'error-500',
          element: <LoadComponent component={ServerError} />,
        },
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute roles={''} component={Layout} />,
      children: [
        { path: '*', element: <PageNotFound /> },
        {
          path: 'access-denied',
          element: <LoadComponent component={AccessDenied} />,
        },
        {
          path: 'MiCuenta',
          element: <PrivateRoute component={miCuenta} />,
        },
        {
          path: 'Notificaciones',
          element: <PrivateRoute component={notificaciones} />,
        },
        {
          path: 'Super',
          children: [
            {
              path: 'VLClientes',
              children: [
                {
                  path: '',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={vlClientes} />,
                },
                {
                  path: 'Add',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={addEditVLCliente} />,
                },
                {
                  path: 'Edit/:vlClienteId',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={addEditVLCliente} />,
                },
              ],
            },
          ],
        },
        {
          path: 'Super',
          children: [
            {
              path: 'VLContactos',
              children: [
                {
                  path: '',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={vlContactos} />,
                },
                {
                  path: 'Add',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={addEditVLContacto} />,
                },
                {
                  path: 'Edit/:vlContactoId',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={addEditVLContacto} />,
                },
              ],
            },
          ],
        },
        {
          path: 'Super',
          children: [
            {
              path: 'VLContactoTipos',
              children: [
                {
                  path: '',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={vlContactoTipos} />,
                },
                {
                  path: 'Add',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={AddEditVLContactoTipo} />,
                },
                {
                  path: 'Edit/:vlContactoTipoId',
                  element: <PrivateRoute roles={appRoles.SuperAdmin} component={AddEditVLContactoTipo} />,
                },
              ],
            },
          ],
        },
        {
          path: 'Sistema',
          children: [
            {
              path: 'Usuarios',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}`} component={usuarios} />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}`}
                      component={addEditUsuario}
                    />
                  ),
                },
                {
                  path: 'Edit/:usuarioId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}`}
                      component={addEditUsuario}
                    />
                  ),
                },
                {
                  path: 'Settings/:usuarioId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}`}
                      component={usuarioSettings}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Festivos',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}`} component={festivos} />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}`}
                      component={addEditFestivo}
                    />
                  ),
                },
                {
                  path: 'Edit/:festivoId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}`}
                      component={addEditFestivo}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'ManoObra',
          children: [
            {
              path: 'TableroDeControl',
              element: (
                <PrivateRoute
                  roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Supervisor}, ${appRoles.Subcontratista}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                  component={tableroDeControl}
                />
              ),
            },
            {
              path: 'TableroDeControlV2',
              element: (
                <PrivateRoute
                  roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Supervisor}, ${appRoles.Subcontratista}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                  component={tableroDeControlV2}
                />
              ),
            },
            {
              path: 'Asistencias',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Supervisor}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={asistencias}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Supervisor}`}
                      component={addEditAsistencia}
                    />
                  ),
                },
                {
                  path: 'Edit/:asistenciaId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Supervisor}`}
                      component={addEditAsistencia}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Asignaciones',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Supervisor}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={asignaciones}
                    />
                  ),
                },
                {
                  path: 'Edit/:asignacionId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Supervisor}`}
                      component={editAsignacion}
                    />
                  ),
                },
              ],
            },
            {
              path: 'TrabajadoresSinCargo',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={trabajadoresSinCargo}
                    />
                  ),
                },
              ],
            },
            {
              path: 'TrabajadoresSinTurno',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={trabajadoresSinTurno}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'Administracion',
          children: [
            {
              path: 'Empresas',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Subcontratista}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={empresas}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditEmpresa}
                    />
                  ),
                },
                {
                  path: 'Edit/:empresaId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditEmpresa}
                    />
                  ),
                },
                {
                  path: 'Details/:empresaId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Subcontratista}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={empresaDetails}
                    />
                  ),
                },
                {
                  path: 'AddSubcontratista/:empresaId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addSubcontratista}
                    />
                  ),
                },
                {
                  path: 'AddSubcontratista/:empresaId/:usuarioId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addSubcontratista}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Remuneraciones',
              children: [
                {
                  path: '',
                  element: <PrivateRoute roles={`${appRoles.SuperAdmin}`} component={remuneraciones} />,
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditRemuneracion}
                    />
                  ),
                },
                {
                  path: 'Edit/:remuneracionId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditRemuneracion}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Cuadrillas',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={cuadrillas}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditCuadrillas}
                    />
                  ),
                },
                {
                  path: 'Edit/:cuadrillaId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditCuadrillas}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Cargos',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={cargos}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditCargos}
                    />
                  ),
                },
                {
                  path: 'Edit/:cargoId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditCargos}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Trabajadores',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Subcontratista}`}
                      component={trabajadores}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Subcontratista}`}
                      component={addEditTrabajador}
                    />
                  ),
                },
                {
                  path: 'Edit/:trabajadorId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Subcontratista}`}
                      component={addEditTrabajador}
                    />
                  ),
                },
                {
                  path: 'Details/:trabajadorId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Subcontratista}, ${appRoles.Fiscalizador}`}
                      component={trabajadorDetails}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Proyectos',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={proyectos}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditProyecto}
                    />
                  ),
                },
                {
                  path: 'Edit/:proyectoId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditProyecto}
                    />
                  ),
                },
                {
                  path: 'Details/:proyectoId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={proyectoDetails}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Partidas',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={partidas}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditPartida}
                    />
                  ),
                },
                {
                  path: 'Edit/:partidaId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditPartida}
                    />
                  ),
                },
              ],
            },
            {
              path: 'Turnos',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={turnos}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditTurno}
                    />
                  ),
                },
                {
                  path: 'Edit/:turnoId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditTurno}
                    />
                  ),
                },
              ],
            },
            {
              path: 'EstadoTrabajadores',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={estadoTrabajadores}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditEstadoTrabajador}
                    />
                  ),
                },
                {
                  path: 'Edit/:estadoTrabajadorId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditEstadoTrabajador}
                    />
                  ),
                },
              ],
            },
            {
              path: 'TipoDocumentos',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={tipoDocumentos}
                    />
                  ),
                },
                {
                  path: 'Add',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditTipoDocumento}
                    />
                  ),
                },
                {
                  path: 'Edit/:tipoDocumentoId',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}`}
                      component={addEditTipoDocumento}
                    />
                  ),
                },
              ],
            },
            {
              path: 'BibliotecaDocumentos',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Subcontratista}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={bibliotecaDocumentos}
                    />
                  ),
                },
              ],
            },
            {
              path: 'DocumentosPorValidar',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      roles={`${appRoles.SuperAdmin}, ${appRoles.SistemAdmin}, ${appRoles.ProjectAdmin}, ${appRoles.Fiscalizador}, ${appRoles.Mandante}`}
                      component={documentosPorValidar}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'Avance',
          children: [
            {
              path: 'Tareas',
              children: [
                {
                  path: '',
                  element: <LoadComponent component={tareas} />,
                },
                {
                  path: 'Add',
                  element: <LoadComponent component={addEditTarea} />,
                },
                {
                  path: 'Edit/:tareaId',
                  element: <LoadComponent component={addEditTarea} />,
                },
              ],
            },
            {
              path: 'Restricciones',
              children: [
                {
                  path: '',
                  element: <LoadComponent component={restricciones} />,
                },
                {
                  path: 'Add',
                  element: <LoadComponent component={addEditRestriccion} />,
                },
                {
                  path: 'Edit/:restriccionId',
                  element: <LoadComponent component={addEditRestriccion} />,
                },
              ],
            },
            {
              path: 'Etiquetas',
              children: [
                {
                  path: '',
                  element: <LoadComponent component={etiquetas} />,
                },
                {
                  path: 'Add',
                  element: <LoadComponent component={addEditEtiqueta} />,
                },
                {
                  path: 'Edit/:etiquetaId',
                  element: <LoadComponent component={addEditEtiqueta} />,
                },
              ],
            },
          ],
        },
        {
          path: 'home',
          children: [
            {
              path: 'PanelDeControl',
              element: <LoadComponent component={panelDeControl} />,
            },
          ],
        },
      ],
    },
  ];
  return useRoutes(routes);
};

export { AllRoutes };
