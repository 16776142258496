import { useUser } from 'hooks';
import { appRoles } from 'pages/uikit/utils';
import { Navigate } from 'react-router-dom';

const Root = () => {
  const { loggedInUser } = useUser();
  const getRootUrl = () => {
    // let url: string = 'manoObra/tableroDeControl';
    let url: string = 'manoObra/tableroDeControlV2';
    return url;
  };

  // const url = loggedInUser?.role === appRoles.Subcontratista ? 'Administracion/Trabajadores' : getRootUrl();
  const url = loggedInUser?.role === appRoles.Subcontratista ? 'manoObra/tableroDeControlV2' : getRootUrl();

  return <Navigate to={`/${url}`} />;
};

export default Root;
