import { PageSize } from 'components';
import config from 'config';

export const datetimeImputFormat = 'yyyy-MM-DDTHH:mm';
export const dateImputFormat = 'yyyy-MM-DD';
export const datetimeViewFormat = 'DD MMM yyyy HH:mm';
export const requiredMessage = 'Este campo es requerido';
export const passwordMessage =
  'Debe contener al menos 8 caracteres, al menos uno en mayúscula, ' +
  'al menos uno en minúscula, al menos un número y al menos un caracter especial';
export const paises = [
  { codigo: 'CL', nombre: 'Chile' },
  { codigo: 'CO', nombre: 'Colombia' },
  { codigo: 'DO', nombre: 'Republica Dominicana' },
];
export const sizePerPageList: PageSize[] = [
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  },
  {
    text: '50',
    value: 50,
  },
  {
    text: '100',
    value: 100,
  },
];

export const formatImageUrl = (imageName: string) => `${config.API_URL}Imagenes?imageName=${imageName}`;
export const formatDocumentUrl = (documentName: string) =>
  `${config.API_URL}TrabajadorDocumentos/GetDocument/${documentName}`;

export const appRoles = {
  SuperAdmin: 'Super Administrador',
  SistemAdmin: 'Administrador de Sistema',
  ProjectAdmin: 'Encargado de Proyectos',
  Supervisor: 'Supervisor',
  Subcontratista: 'Subcontratista',
  Fiscalizador: 'Fiscalizador',
  Mandante: 'Mandante',
};

export const formatTime = (timeNumber: number | undefined) => {
  const time = timeNumber?.toString();
  if (!time) return '';
  if (time.length === 2) return time;
  return `0${time}`;
};

export const dayLabels = [
  { label: 'Monday', id: 1 },
  { label: 'Tuesday', id: 2 },
  { label: 'Wednesday', id: 3 },
  { label: 'Thursday', id: 4 },
  { label: 'Friday', id: 5 },
  { label: 'Saturday', id: 6 },
  { label: 'Sunday', id: 0 },
];

export const validatePassword = (p?: string) => {
  const value = p ?? '';
  if (value?.length < 8) return false;
  if (value?.search(/[a-z]/) < 0) return false;
  if (value?.search(/[0-9]/) < 0) return false;
  if (value?.search(/[A-Z]/) < 0) return false;
  if (value?.search(/[^a-z0-9]+/gi) < 0) return false;

  return true;
};
