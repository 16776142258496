import { appRoles } from 'pages/uikit/utils';

export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  children?: MenuItemType[];
  roles?: string[];
  hasWarning?: boolean;
};

const MENU_ITEMS: MenuItemType[] = [
  { key: 'vadoLabs', label: '', isTitle: true },
  {
    key: 'PanelDeControl',
    label: 'Tablero de Control',
    url: '/manoObra/tableroDeControlV2',
    parentKey: 'home',
    icon: 'uil-home-alt',
  },
  {
    key: 'Super',
    label: 'Super Administración',
    isTitle: false,
    children: [
      {
        key: 'VLClientes',
        label: 'Clientes',
        url: '/super/VLClientes',
        parentKey: 'Super',
        roles: [appRoles.SuperAdmin],
      },
      {
        key: 'VLContactos',
        label: 'Contactos',
        url: '/super/VLContactos',
        parentKey: 'Super',
        roles: [appRoles.SuperAdmin],
      },
      {
        key: 'VLContactoTipos',
        label: 'Tipos de Contacto',
        url: '/super/VLContactoTipos',
        parentKey: 'Super',
        roles: [appRoles.SuperAdmin],
      },
    ],
  },
  {
    key: 'Sistema',
    label: 'Sistema',
    isTitle: false,
    children: [
      {
        key: 'Usuarios',
        label: 'Usuarios',
        isTitle: false,
        url: '/sistema/Usuarios',
        parentKey: 'Sistema',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
      {
        key: 'Festivos',
        label: 'Festivos',
        isTitle: false,
        url: '/sistema/Festivos',
        parentKey: 'Sistema',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
    ],
  },

  {
    key: 'Administración',
    label: 'Administración',
    isTitle: false,
    children: [
      {
        key: 'Empresas',
        label: 'Empresas',
        isTitle: false,
        url: '/Administracion/Empresas',
        parentKey: 'Administración',
        roles: [
          appRoles.SuperAdmin,
          appRoles.SistemAdmin,
          appRoles.ProjectAdmin,
          appRoles.Subcontratista,
          appRoles.Fiscalizador,
          appRoles.Mandante,
        ],
      },
      {
        key: 'Trabajadores',
        label: 'Trabajadores',
        isTitle: false,
        url: '/Administracion/Trabajadores',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin, appRoles.ProjectAdmin, appRoles.Subcontratista],
      },
      {
        key: 'Remuneraciones',
        label: 'Remuneraciones',
        isTitle: false,
        url: '/Administracion/Remuneraciones',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin],
      },
      {
        key: 'Proyectos',
        label: 'Proyectos',
        isTitle: false,
        url: '/Administracion/Proyectos',
        parentKey: 'Administración',
        roles: [
          appRoles.SuperAdmin,
          appRoles.SistemAdmin,
          appRoles.ProjectAdmin,
          appRoles.Fiscalizador,
          appRoles.Mandante,
        ],
      },
      {
        key: 'Partidas',
        label: 'Partidas',
        isTitle: false,
        url: '/Administracion/Partidas',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
      {
        key: 'Cargos',
        label: 'Cargos',
        isTitle: false,
        url: '/Administracion/Cargos',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
      {
        key: 'Turnos',
        label: 'Turnos',
        isTitle: false,
        url: '/Administracion/Turnos',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
      {
        key: 'Cuadrillas',
        label: 'Cuadrillas',
        isTitle: false,
        url: '/Administracion/Cuadrillas',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
      {
        key: 'EstadoTrabajadores',
        label: 'Estados de Trabajadores',
        isTitle: false,
        url: '/Administracion/EstadoTrabajadores',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
      {
        key: 'TipoDocumentos',
        label: 'Tipos de Documentos',
        isTitle: false,
        url: '/Administracion/TipoDocumentos',
        parentKey: 'Administración',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin],
      },
      {
        key: 'BibliotecaDocumentos',
        label: 'Biblioteca de Documentos',
        isTitle: false,
        url: '/Administracion/BibliotecaDocumentos',
        parentKey: 'Administración',
        roles: [
          appRoles.SuperAdmin,
          appRoles.SistemAdmin,
          appRoles.ProjectAdmin,
          appRoles.Subcontratista,
          appRoles.Fiscalizador,
          appRoles.Mandante,
        ],
      },
      {
        key: 'DocumentosPorValidar',
        label: 'Documentos por Validar',
        isTitle: false,
        url: '/Administracion/DocumentosPorValidar',
        parentKey: 'Administración',
        roles: [
          appRoles.SuperAdmin,
          appRoles.SistemAdmin,
          appRoles.ProjectAdmin,
          appRoles.Fiscalizador,
          appRoles.Mandante,
        ],
      },
    ],
  },
  {
    key: 'ManoObra',
    label: 'Control de Mano de Obra',
    isTitle: false,
    children: [
      {
        key: 'Tablero de Control',
        label: 'Tablero de Control',
        isTitle: false,
        url: '/ManoObra/TableroDeControl',
        parentKey: 'ManoObra',
        roles: [
          appRoles.SuperAdmin,
          appRoles.SistemAdmin,
          appRoles.ProjectAdmin,
          appRoles.Supervisor,
          appRoles.Fiscalizador,
          appRoles.Mandante,
        ],
      },
      {
        key: 'Asistencias',
        label: 'Asistencias',
        isTitle: false,
        url: '/ManoObra/Asistencias',
        parentKey: 'ManoObra',
        roles: [
          appRoles.SuperAdmin,
          appRoles.SistemAdmin,
          appRoles.ProjectAdmin,
          appRoles.Supervisor,
          appRoles.Fiscalizador,
          appRoles.Mandante,
        ],
      },
      {
        key: 'Asignaciones',
        label: 'Asignaciones',
        isTitle: false,
        url: '/ManoObra/Asignaciones',
        parentKey: 'ManoObra',
        roles: [
          appRoles.SuperAdmin,
          appRoles.SistemAdmin,
          appRoles.ProjectAdmin,
          appRoles.Supervisor,
          appRoles.Fiscalizador,
          appRoles.Mandante,
        ],
      },
      {
        key: 'TrabajadoresSinCargo',
        label: 'Trabajadores sin Cargo',
        isTitle: false,
        url: '/ManoObra/TrabajadoresSinCargo',
        parentKey: 'ManoObra',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin, appRoles.ProjectAdmin],
      },
      {
        key: 'TrabajadoresSinTurno',
        label: 'Trabajadores sin Turno',
        isTitle: false,
        url: '/ManoObra/TrabajadoresSinTurno',
        parentKey: 'ManoObra',
        roles: [appRoles.SuperAdmin, appRoles.SistemAdmin, appRoles.ProjectAdmin],
      },
    ],
  },
  {
    key: 'Avance',
    label: 'Control de Avance',
    isTitle: false,
    children: [
      {
        key: 'Tareas',
        label: 'Tareas',
        isTitle: false,
        url: '/Avance/Tareas',
        parentKey: 'Avance',
        roles: [appRoles.SuperAdmin],
      },
      {
        key: 'Restricciones',
        label: 'Restricciones',
        isTitle: false,
        url: '/Avance/Restricciones',
        parentKey: 'Avance',
        roles: [appRoles.SuperAdmin],
      },
      {
        key: 'Etiquetas',
        label: 'Etiquetas',
        isTitle: false,
        url: '/Avance/Etiquetas',
        parentKey: 'Avance',
        roles: [appRoles.SuperAdmin],
      },
    ],
  },
];

export { MENU_ITEMS };
