import { AnyAction, Dispatch } from 'redux';
import { APICore } from 'helpers/api/apiCore';
import { ProyectoActionTypes } from './constants';

export const getProyectos = async (dispatch: Dispatch<AnyAction>) => {
  const request = await APICore.get('Proyectos?onlyMy=true');
  let selectedProyectoId = localStorage.getItem('selectedProyectoId');
  if (!selectedProyectoId && request.data.length > 0) {
    selectedProyectoId = request.data[0].id;
    localStorage.setItem('selectedProyectoId', selectedProyectoId!);
  }
  if (
    selectedProyectoId &&
    request.data.length > 0 &&
    request.data.filter((x: any) => x.id === parseInt(`${selectedProyectoId}`)).length === 0
  ) {
    selectedProyectoId = request.data[0].id;
    localStorage.setItem('selectedProyectoId', selectedProyectoId!);
  }
  dispatch({
    type: ProyectoActionTypes.GET_PROYECTOS_COMPLETED,
    payload: {
      proyectos: request.data,
      selectedProyectoId,
    },
  });
};

export const OnProyectoChange = (selectedProyectoId: number) => {
  localStorage.setItem('selectedProyectoId', selectedProyectoId.toString());
  return {
    type: ProyectoActionTypes.SELECT_PROYECTO,
    payload: {
      selectedProyectoId,
    },
  };
};
