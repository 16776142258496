import { Navigate, useLocation } from 'react-router-dom';
import { isUserAuthenticated } from 'helpers/api/apiCore';
import { useUser } from 'hooks';
import { Suspense } from 'react';

type PrivateRouteProps = {
  component: React.ComponentType;
  roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
  let location = useLocation();
  const { loggedInUser } = useUser();

  /**
   * not logged in so redirect to login page with the return url
   */
  if (isUserAuthenticated() === false) {
    return <Navigate to={'/account/login'} state={{ from: location }} replace />;
  }

  // check if route is restricted by role
  if (roles && roles.indexOf(loggedInUser.role) === -1) {
    // role not authorised so redirect to home page
    return <Navigate to={{ pathname: '/' }} />;
  }

  return (
    <Suspense fallback={<div className=""></div>}>
      <RouteComponent />
    </Suspense>
  );
};

export default PrivateRoute;
