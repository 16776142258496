import jwtDecode from 'jwt-decode';
import axios, { AxiosRequestConfig } from 'axios';
import config from '../../config';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      window.location.href = '/access-denied';
    } else {
      switch (error.response.status) {
        case 401:
          message = 'Invalid credentials';
          break;
        case 403:
          message = 'Access Forbidden';
          break;
        case 404:
          message = 'Sorry! the data you are looking for could not be found';
          break;
        default: {
          message = error.response && error.response.data ? error.response.data['message'] : error.message || error;
        }
      }
      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} accessToken
 */
const setAuthorization = (accessToken: string | null) => {
  if (accessToken) axios.defaults.headers.common['Authorization'] = 'bearer ' + accessToken;
  else delete axios.defaults.headers.common['Authorization'];
};

const setCustomHeaders = () => {
  const selectedVlClienteId = localStorage.getItem('selectedVlClienteId');
  if (selectedVlClienteId) {
    axios.defaults.headers.common['selectedVlClienteId'] = selectedVlClienteId;
  }

  const selectedProyectoId = localStorage.getItem('selectedProyectoId');
  if (selectedProyectoId) {
    axios.defaults.headers.common['selectedProyectoId'] = selectedProyectoId;
  }
};

const getUserFromSession = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const APICore = {
  /**
   * Fetches data from given url
   */
  get: (url: string, params: any = null) => {
    setCustomHeaders();
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  },

  getFile: (url: string, params: any) => {
    setCustomHeaders();
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
    } else {
      response = axios.get(`${url}`, { responseType: 'blob' });
    }
    return response;
  },

  getMultiple: (urls: string, params: any) => {
    setCustomHeaders();
    const reqs = [];
    let queryString = '';
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : '';
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  },

  /**
   * post given data to url
   */
  create: (url: string, data: any, config?: AxiosRequestConfig<any>) => {
    setCustomHeaders();
    return axios.post(url, data, config);
  },

  /**
   * Updates patch data
   */
  updatePatch: (url: string, data: any) => {
    setCustomHeaders();
    return axios.patch(url, data);
  },

  /**
   * Updates data
   */
  update: (url: string, data: any) => {
    setCustomHeaders();
    return axios.put(url, data);
  },

  /**
   * Deletes data
   */
  delete: (url: string) => {
    setCustomHeaders();
    return axios.delete(url);
  },

  /**
   * post given data to url with file
   */
  createWithFile: (url: string, data: any) => {
    setCustomHeaders();
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  },

  /**
   * post given data to url with file
   */
  updateWithFile: (url: string, data: any) => {
    setCustomHeaders();
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.patch(url, formData, config);
  },
};

const isUserAuthenticated = () => {
  const user = getLoggedInUser();

  if (!user || (user && !user.accessToken)) {
    setLoggedInUser(null);
    return false;
  }
  const decoded: any = jwtDecode(user.accessToken);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access accessToken expired');
    setLoggedInUser(null);
    return false;
  } else {
    return true;
  }
};

const getLoggedInUser = () => {
  return getUserFromSession();
};

const setUserInSession = (modifiedUser: any) => {
  let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (userInfo) {
    const { accessToken, user } = JSON.parse(userInfo);
    setLoggedInUser({ accessToken, ...user, ...modifiedUser });
  }
};

const setLoggedInUser = (session: any) => {
  if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
  else {
    sessionStorage.removeItem(AUTH_SESSION_KEY);
  }
};

let user = getUserFromSession();
if (user) {
  const { accessToken } = user;
  if (accessToken) {
    setAuthorization(accessToken);
  }
}

export { APICore, axios, setAuthorization, setLoggedInUser, setUserInSession, getLoggedInUser, isUserAuthenticated };
